<template>
  <TheContent title="All Links" :pageSize.sync="pageSize">
    <CDataTable hover striped sorter :loading="loading" :items="data" :fields="fields" index-column>
      <template #link="{item}">
        <td>
          <span>{{`${clientDomen}/login?referral=${item.code}`}}</span>
        </td>
      </template>
    </CDataTable>
  </TheContent>
</template>

<script>
import TheContent from "@/containers/TheContent";

import MixinsScreen from "@/mixins/screen";

import { clientDomen } from "@/utils/constants";

export default {
  name: "Transactions",

  mixins: [MixinsScreen],

  components: {
    TheContent
  },

  data: () => {
    return {
      apiName: "users/get_referral_code",

      fields: [
        { key: "link", label: "Code", sorter: false },
        { key: "visits", label: "Visits", sorter: false }
      ],

      filters: {
        dataDefault: {
          id: null,
          order: null,
          user: []
        },

        items: {
          id: {
            type: "select",
            label: "Select id"
          },

          user: {
            type: "complete",
            label: "Select users",
            apiName: "users",
            size: "3"
          },
          order: {
            type: "select",
            label: "Select orders",
            size: "3"
          }
        }
      }
    };
  },

  created() {
    this.clientDomen = clientDomen;
  },

  methods: {
    getBadge(status) {
      return status === "completed" ? "success" : "danger";
    },

    userLink(id) {
      return `users/${id.toString()}`;
    }
  }
};
</script>
 